<template>
  <div class="ui-input-csv">
    <UiItem
      v-if="!!file"
      @click="dialogIsOpen = true"
      class="ui-outset"
      :text="file.name"
      :secondary="`${commitedRecords.length} registros`"
    >
      <template #right>
        <UiIcon
          value="g:clear"
          @click="reset()"
        />
      </template>
    </UiItem>

    <div v-show="!file">
      <input
        class="ui-inset"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="fileInputChange($event)"
      />
    </div>

    <UiDialog
      v-if="file"
      :open.sync="dialogIsOpen"
    >
      <template #header>
        <div class="ui-card ui-row --tight">
          <UiButtonSelection
            :actions="[{label: 'todos', action: selectAll}, {label: 'ninguno', action: selectNone}]"
            :count="selectedRecords.length"
            :total="records.length"
          />

          <UiItem
            :text="file.name"
            :secondary="`${selectedRecords.length} registros`"
          />
        </div>
      </template>

      <template #contents>
        <div class="ui-input-csv-preview">
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
          >
            <tbody>
              <tr
                v-for="(record, index) in previewRecords"
                :key="index"
                :class="{disabled: !selected[index]}"
              >
                <td width="1">
                  <input
                    type="checkbox"
                    v-model="selected[index]"
                  />
                </td>
                <td
                  v-for="(value, i) in record"
                  :key="i"
                  v-text="value"
                  nowrap
                  @click="$set(selected,index,!selected[index])"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <span
          class="ui-input-preview-more"
          v-show="records.length > previewRecords.length"
          @click="previewSize = records.length"
        >{{records.length - previewRecords.length}} registros mas</span>
      </template>

      <template #footer>
        <button
          type="button"
          class="ui-button"
          @click="accept()"
        >Aceptar</button>
        <button
          type="button"
          @click="cancel()"
          class="ui-button --cancel"
        >Cancelar</button>
      </template>
    </UiDialog>
  </div>
</template>

<script>
import Papa from 'papaparse';

import UiItem from '../UiItem/UiItem.vue';
import UiIcon from '../UiIcon/UiIcon.vue';
import UiDialog from '../UiDialog/UiDialog.vue';
import UiButtonSelection from '../UiButtonSelection/UiButtonSelection.vue';

export default {
  name: 'UiInputCsv',

  components: {
    UiItem,
    UiIcon,
    UiDialog,
    UiButtonSelection,
  },

  data() {
    return {
      file: null,
      records: [],
      selected: {},

      dialogIsOpen: false,
      previewSize: 10,

      commitedRecords: [],
    };
  },

  computed: {
    previewRecords() {
      return this.records.slice(0, this.previewSize);
    },

    selectedRecords() {
      return this.records.filter((element, index) => this.selected[index]);
    },
  },

  methods: {
    selectAll() {
      this.records.forEach((record, index) =>
        this.$set(this.selected, index, true)
      );
    },

    selectNone() {
      this.records.forEach((record, index) =>
        this.$set(this.selected, index, false)
      );
    },

    fileInputChange(event) {
      this.file = event.target.files[0];
      this.readFile(this.file);
      this.dialogIsOpen = true;
    },

    readFile(file) {
      Papa.parse(file, {
        complete: (results) => {
          this.records = results.data;
          this.records.forEach((record, index) =>
            this.$set(this.selected, index, index != 0)
          );
        },
      });
    },

    accept() {
      this.commitedRecords = this.selectedRecords.slice(0); // clone array
      this.$emit('input', this.commitedRecords);
      this.dialogIsOpen = false;
    },

    cancel() {
      this.dialogIsOpen = false;
      if (!this.commitedRecords.length) {
        this.reset();
      }
    },

    reset() {
      this.$el.querySelector('input').value = '';

      this.file = null;
      this.records = [];
      this.commitedRecords = [];

      this.$emit('input', this.records);
    },
  },
};
</script>

<style lang="scss">
// No se declara dentro de .ui-input-csv porque el <UiDialog> que lo contiene se agrega a <body>
.ui-input-csv-preview {
  max-height: 60vh;
  overflow: auto;

  table {
    width: 99%;
    margin: auto;
    border-collapse: collapse;

    tbody {
      td {
        font-size: 0.8em;
        padding: 3px;
        border: 1px solid #ccc;
        background-color: #fff;

        cursor: pointer;
      }

      tr.disabled {
        td {
          opacity: 0.4;
          // border-color: transparent;
        }
      }

      tr:hover td {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>